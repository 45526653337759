<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 93 93"
  >
    <g transform="translate(-637 -5330)">
      <circle
        cx="46.5"
        cy="46.5"
        r="46.5"
        fill="currentColor"
        transform="translate(637 5330)"
      />
      <path
        fill="#fff"
        d="M697.287 5373.218a4 4 0 0 1 0 6.565l-17 11.84a4 4 0 0 1-6.287-3.283v-23.68a4 4 0 0 1 6.286-3.282Z"
      />
    </g>
  </svg>
</template>
